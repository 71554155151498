<template>
  <fragment>
    <wx-form-container class="wizard">
      <v-form ref="rejectCategoryForm" lazy-validation>
        <fieldset>
          <legend class="legend-header top-legend">{{ $t("common.information") }}</legend>
          <v-row>
            <v-col cols="12" sm="6">
              <!-- 
                Code - - - - - - - - - - - - - - -
                -->
              <wx-text-field
                v-model.trim="categoryCode"
                :label="categoryCodeLabel"
                :rules="[() => validateCode()]"
                :counter="rejectCategoryCodeMaxLength"
                :maxlength="rejectCategoryCodeMaxLength"
                :error="categoryCodeError"
                :error-messages="categoryCodeErrorMessages"
              />
            </v-col>
            <v-col cols="12">
              <!-- 
                Name - - - - - - - - - - - - - - -
                -->
              <wx-text-field
                v-model.trim="categoryName"
                :rules="[() => validateName()]"
                :label="categoryNameLabel"
                :counter="rejectCategoryNameMaxLength"
                :maxlength="rejectCategoryNameMaxLength"
              />
            </v-col>
          </v-row>
        </fieldset>
        <fieldset>
          <legend class="legend-header">{{ $t("rejectReason.parentCategory") }}</legend>
          <v-row>
            <v-col cols="12">
              <!-- 
                Parent Category - - - - - - - - - - -
                -->
              <wx-autocomplete
                v-model="selectedParentCategoryId"
                :items="availableParentCategories"
                item-text="name"
                item-value="id"
                :label="parentCategoryLabel"
                :rules="[() => validateParentCategoryId()]"
                validate-on-blur
              >
                <template v-slot:item="{ item }">
                  <span v-if="hasParents(item)" class="ml-1 d-inline wx-typo-sm font-weight-light parentalLine">{{
                    item.parentalLine
                  }}</span>
                  <span class="d-block wx-typo-norm ml-1">{{ item.name }}</span>
                </template>
              </wx-autocomplete>
            </v-col>
          </v-row>
        </fieldset>
        <form-footer-actions :click-submit="submit" :click-cancel="back" />
      </v-form>
    </wx-form-container>
  </fragment>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrorHandling from "@/components/ErrorHandling";
import FormFooterActions from "@/components/ui/FormFooterActions";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import RouteService from "@/router/RouteService";
import Validations from "@/components/validations";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxFormContainer from "@/components/ui/WxFormContainer";
import WxTextField from "@/components/ui/WxTextField";

function buildHierarchyCategoryOptionText(category) {
  return `${category.name} (${category.code})`;
}

function buildCategoryHierarchy(categoryId, categoryById, hierarchy) {
  if (categoryId) {
    const category = categoryById.get(categoryId);
    hierarchy.push(buildHierarchyCategoryOptionText(category));
    buildCategoryHierarchy(category.parentCategoryId, categoryById, hierarchy);
  }
}

export default {
  name: "RejectCategoryWizard",
  components: {
    FormFooterActions,
    WxAutocomplete,
    WxFormContainer,
    WxTextField,
  },
  props: {
    initialParentCategory: {
      type: Object,
      required: true,
    },
    initialCategory: {
      type: Object,
      default: () => null,
    },
    allCategories: {
      type: Array,
      required: true,
    },
    categoryById: {
      type: Map,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      categoryCode: null,
      categoryName: null,
      selectedParentCategoryId: "-",
      categoryCodeError: false,
      categoryCodeErrorMessages: [],

      rejectCategoryCodeMaxLength: Validations.rejectCategoryCodeMaxLength,
      rejectCategoryNameMaxLength: Validations.rejectCategoryNameMaxLength,
    };
  },
  watch: {
    initialCategory() {
      if (this.initialCategory) {
        this.id = this.initialCategory.id;
        this.categoryCode = this.initialCategory.code;
        this.categoryName = this.initialCategory.name;
      }
    },
    initialParentCategory() {
      if (this.initialParentCategory) {
        if (this.initialParentCategory.id === "-") {
          this.selectedParentCategoryId = "-";
        } else {
          this.selectedParentCategoryId = this.initialParentCategory.id;
        }
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
    categoryCodeLabel() {
      return this.$t("rejectReason.categoryCode") + " *";
    },
    categoryNameLabel() {
      return this.$t("rejectReason.categoryName") + " *";
    },
    parentCategoryLabel() {
      return this.$t("rejectReason.parentCategory") + " *";
    },
    availableParentCategories() {
      let options = [];
      let categoriesWithoutSelf = this.allCategories.filter((c) => c.id !== this.id); // Remove the category being edited!

      let categoryOptions = categoriesWithoutSelf
        .map((category) => {
          let hierarchy = [];
          buildCategoryHierarchy(category.id, this.categoryById, hierarchy);
          return {
            category: category,
            hierarchy: hierarchy.reverse(),
          };
        })
        .map((categoryAndHierarchy) => {
          const category = categoryAndHierarchy.category;
          const hierarchy = categoryAndHierarchy.hierarchy;
          const parentalLine = hierarchy.length > 1 ? hierarchy.slice(0, hierarchy.length - 1) : [];
          const parentalLineText = parentalLine.length > 0 ? parentalLine.join(" / ") + " /" : null;
          return {
            parentalLine: parentalLineText,
            name: buildHierarchyCategoryOptionText(category),
            id: category.id,
          };
        });

      options.push(...categoryOptions);
      return options;
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchAvailableRejectReasons"]),
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    getRouteBack(optionalNewCategoryId) {
      const expandId = optionalNewCategoryId ? optionalNewCategoryId : this.initialParentCategory.id;
      return RouteService.toRejectReasons(expandId);
    },
    back() {
      return this.$router.push(this.getRouteBack());
    },
    hasParents(item) {
      return !!item.parentalLine;
    },
    validateCode() {
      if (!this.categoryCode) {
        return this.$t("rejectReason.errors.noCategoryCode");
      }
      if (this.categoryCode.trim().length > this.rejectCategoryCodeMaxLength) {
        return this.$t("rejectReason.errors.categoryCodeTooLong");
      }
      if (!Validations.isRejectCategoryCodeValid(this.categoryCode)) {
        return this.$t("rejectReason.errors.categoryCodeHasInvalidChar");
      }
      this.categoryCodeError = false;
      this.categoryCodeErrorMessages = [];
      return true;
    },
    validateName() {
      if (!this.categoryName) {
        return this.$t("rejectReason.errors.noCategoryName");
      }
      if (this.categoryName.trim().length > this.rejectCategoryNameMaxLength) {
        return this.$t("rejectReason.errors.categoryNameTooLong");
      }
      if (!Validations.isRejectCategoryNameValid(this.categoryName)) {
        return this.$t("rejectReason.errors.categoryNameHasInvalidChar");
      }
      return true;
    },
    validateParentCategoryId() {
      if (!this.selectedParentCategoryId) {
        return this.$t("rejectReason.errors.noParentCategory");
      }
      return true;
    },
    isCreation() {
      return this.id === null;
    },
    isEdition() {
      return this.id !== null;
    },
    submit() {
      const isValid = this.$refs.rejectCategoryForm.validate();
      if (isValid) {
        if (this.isCreation()) {
          this.create();
        }
        if (this.isEdition()) {
          this.update();
        }
      }
    },
    create() {
      const parentCategoryId = this.selectedParentCategoryId === "-" ? null : this.selectedParentCategoryId;
      let newCategory = {
        code: this.categoryCode,
        name: this.categoryName,
        parent_category_id: parentCategoryId,
      };
      RejectReasonService.createCategory(this.activeFactoryId, newCategory)
        .then((response) => this.handleCategoryCreationResponse(response, newCategory))
        .catch((error) => this.handleCategoryError(error.response));
    },
    handleCategoryCreationResponse(httpResponse, newCategory) {
      if (httpResponse.status === 201) {
        this.fetchAvailableRejectReasons();
        const newCategoryId = httpResponse.data.id;
        this.showOperationSuccess(
          this.$t("rejectReason.successfullyCategoryCreated", {
            code: newCategory.code,
            name: newCategory.name,
          }),
        );
        this.$router.push(this.getRouteBack(newCategoryId));
      }
    },
    update() {
      const parentCategoryId = this.selectedParentCategoryId === "-" ? null : this.selectedParentCategoryId;
      let updatedCategory = {
        id: this.id,
        code: this.categoryCode,
        name: this.categoryName,
        parent_category_id: parentCategoryId,
      };
      RejectReasonService.updateCategory(this.id, updatedCategory)
        .then((response) => this.handleCategoryUpdateResponse(response, updatedCategory))
        .catch((error) => this.handleCategoryError(error.response));
    },
    handleCategoryUpdateResponse(httpResponse, updatedCategory) {
      if (httpResponse.status === 200) {
        this.fetchAvailableRejectReasons();
        this.showOperationSuccess(
          this.$t("rejectReason.successfullyCategoryUpdated", {
            code: updatedCategory.code,
            name: updatedCategory.name,
          }),
        );
        this.$router.push(this.getRouteBack(this.id));
      }
    },
    handleCategoryError(httpResponse) {
      const errorMessage = ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage);
      if (httpResponse.status === 409) {
        this.categoryCodeError = true;
        this.categoryCodeErrorMessages = errorMessage;
      } else {
        this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      }
    },
    getErrorMessage(code) {
      switch (code) {
        case "CS_REJC_PUT_20001":
        case "CS_REJC_POST_20001": {
          return this.$t("rejectReason.errors.duplicateCategory");
        }
        default: {
          return this.$t("common.errors.default", { code: code });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  margin-bottom: 20px;
}

.form-box {
  max-width: 70%;
  padding: var(--box-padding-admin);
  background-color: var(--color-element-layer1);
  box-shadow: var(--box-shadow-small-elevation);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border-theme);

  h2 {
    margin-bottom: 20px;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.parentalLine {
  color: var(--color-text-subtle-theme);
}
</style>
