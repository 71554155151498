<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ title }}</h1>

    <reject-category-wizard
      :initial-category="category"
      :initial-parent-category="parentCategory"
      :all-categories="allCategories"
      :category-by-id="categoryById"
    />
  </article>
</template>

<script>
import RejectCategoryWizard from "../components/rejectreason/RejectCategoryWizard";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    RejectCategoryWizard,
  },
  data() {
    return {
      isCreation: true,
      category: {},
      parentCategory: {},
      categoryById: new Map(),
      allCategories: [],
    };
  },
  watch: {
    async activeFactory() {
      if (this.activeFactory) {
        await this.fetchCategories();
      }
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    title() {
      return this.isCreation
        ? this.$t("rejectReason.wizard.categoryCreation.title")
        : this.$t("rejectReason.wizard.categoryEdition.title");
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    async fetchCategories() {
      RejectReasonService.getRejectReasonTree(this.activeFactory.id)
        .then((httpResponse) => this.handleCategoryRetrievalResponse(httpResponse))
        .catch((error) => this.handleReasonRetrievalError(error.response));
    },
    handleCategoryRetrievalResponse(httpResponse) {
      let categoryMap = new Map();
      this.buildCategoryById(httpResponse.data.children, null, categoryMap);
      this.categoryById = categoryMap;
      let categories = [];
      this.categoryById.forEach((value) => categories.push(value));
      this.allCategories = categories;

      this.parentCategoryId = this.$route.query.parentCategoryId;
      let parent = { id: "-" };
      if (this.parentCategoryId) {
        parent = this.categoryById.get(this.parentCategoryId);
      }
      this.categoryId = this.$route.query.categoryId;
      if (this.categoryId) {
        this.category = this.categoryById.get(this.categoryId);
        parent = this.categoryById.get(this.category.parentCategoryId);
      }
      if (parent) {
        this.parentCategory = parent;
      }

      if (this.parentCategory && this.categoryId) {
        this.isCreation = false;
        this.showWizard = true;
      } else {
        this.isCreation = true;
        this.showWizard = true;
      }
    },
    buildCategoryById(children, parentCategoryId, destinationMap) {
      children.forEach((element) => {
        if (element.type === "category") {
          destinationMap.set(element.id, {
            id: element.id,
            code: element.code,
            name: element.name,
            parentCategoryId: parentCategoryId,
          });
          if (element.children.length > 0) {
            this.buildCategoryById(element.children, element.id, destinationMap);
          }
        }
      });
    },
    handleReasonRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toRejectReasons());
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
  async mounted() {
    if (this.activeFactory) {
      await this.fetchCategories();
    }
  },
};
</script>
